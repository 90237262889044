<template>
  <div>
    <el-card class="box-card">
      <div class="createdWrap">
        <div class="title">创建考试</div>
        <div>
          <el-input
            v-model="title"
            class="input-width"
            clearable
            placeholder="请输入2-36字考试标题"
          ></el-input>
        </div>
        <div class="test">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="testInfo"
            :class="{ active: currentIndex == index }"
            @click="choice(index)"
          >
            <div class="icon">
              <i :class="item.icon"></i>
            </div>
            <div class="testTitle">{{ item.title }}</div>
            <div class="text">
              <p>{{ item.text }}</p>
              <p>{{ item.txetAnswer }}</p>
            </div>
            <div class="space"><img src="../../../assets/examination/testIcon.png" alt="" /></div>
          </div>
        </div>
        <el-button type="primary" class="btn" @click="createdTest">
          创建考试
        </el-button>
        <div class="remarks">
          <p>注：根据中国相关法规和主管部门要求，不允许发布与政治、军事、宗教，信仰，民族，</p>
          <p>人权、民主、国家主权、国家统一、外交事件 等相关的 敏感话题调查，请您谅解！</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getCreatedtest } from '@/api/examination'
import { mapState, mapMutations } from 'vuex'
import * as type from '@/store/action-types'
import to from 'await-to'
export default {
  data() {
    return {
      title: '',
      currentIndex: -1,
      list: [
        {
          icon: 'el-icon-tickets',
          title: '随机试卷',
          text: '题库抽题 随时答题',
          txetAnswer: '系统自行阅卷',
        },
        {
          icon: 'el-icon-document',
          title: '自定义试卷',
          text: '试题 时间 分值 顺序随心定制',
          txetAnswer: '智能阅卷 成绩分析',
        },
      ],
    }
  },
  computed: {
    ...mapState('user', ['questionBank']),
  },
  created() {},
  methods: {
    ...mapMutations('user', [type.SET_QUESTION_BANK]),
    createdTest() {
      if (this.title == '') {
        this.$message({ type: 'warning', message: '请输入考试标题' })
      } else if (this.title.length < 2) {
        this.$message({ type: 'warning', message: '请输入2-36字考试标题' })
      } else if (this.title.length > 36) {
        this.$message({ type: 'warning', message: '请输入2-36字考试标题' })
      } else if (this.currentIndex == -1) {
        this.$message({ type: 'warning', message: '请选择试卷类型' })
      } else if (this.currentIndex == 1) {
        this.$message({ type: 'warning', message: '自定义考试还未开放' })
      } else {
        this.getCreatedtestData()
      }
    },
    async getCreatedtestData() {
      const [res, err] = await to(
        getCreatedtest({ examTitle: this.title, paperType: this.currentIndex }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '创建成功' })
      if (this.currentIndex == 0) {
        this.$router.push({ path: 'random', query: { examId: res.data } })
        this[type.SET_QUESTION_BANK]({ type: 0, list: [] })
      } else {
        this.$router.push('custom')
      }
    },
    choice(i) {
      this.currentIndex = i
    },
  },
}
</script>

<style scoped lang="scss">
.createdWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 100px;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
  }
  .input-width {
    width: 362px;
    margin-top: 25px;
    margin-bottom: 42px;
  }
  .test {
    display: flex;
    .testInfo {
      cursor: pointer;
      &:last-child {
        margin-left: 108px !important;
      }
      &:last-child .icon {
        background: #849dff;
      }
      width: 220px;
      height: 220px;
      background: #f8f8f8;
      display: flex;
      flex-direction: column;
      align-items: center;
      float: left;
      border-radius: 9px;
      position: relative;
      .icon {
        width: 76px;
        height: 76px;
        border-radius: 50%;
        background: #66e277;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 22px 0;
        i {
          font-size: 28px;
          color: #fff;
        }
      }
      .testTitle {
        font-size: 16px;
        color: #606266;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .text {
        font-size: 14px;
        color: #909399;
        text-align: center;
      }
      .space {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        img {
          width: 27px;
          height: 27px;
        }
      }
    }
  }
  .btn {
    margin-top: 58px;
  }
  .remarks {
    color: #909399;
    font-size: 14px;
    line-height: 18px;
    margin-top: 27px;
  }
  .active {
    border: 1px solid #ff7b33 !important;
    .space {
      display: block !important;
    }
  }
}
</style>
